import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { HeaderEnum, SystemLogInterface, SystemLogLevelEnum, UserProfileSourceEnum } from 'gigabyte-shared';
import { CoreAPI } from '../../constants/core-api';
import { HTTPCoreErrorResponse } from '../../hooks/use-http';

const axiosInstance: AxiosInstance = axios.create();

const setHeadersValues = (config: AxiosRequestConfig<any>) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const id = window.localStorage.getItem(HeaderEnum.USER_ID);

  config.baseURL = process.env.REACT_APP_API_BASE_URL;

  // TODO: review to remove "as unknow"
  config.headers = {
    ...config.headers,
    'x-api-key': process.env.REACT_APP_API_KEY,
    'Content-Type': 'application/json',
  } as unknown as AxiosRequestHeaders;

  if (accessToken !== null) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    } as unknown as AxiosRequestHeaders;

    if (config.headers['x-user-id'] === undefined) {
      config.headers = {
        ...config.headers,
        'x-user-id': id,
      } as unknown as AxiosRequestHeaders;
    }
  }
};

export const sendError = async (systemLogLevel: SystemLogLevelEnum, message: string, stacktrace?: string) => {
  const errorAxiosInstance: AxiosInstance = axios.create();
  errorAxiosInstance.interceptors.request.use((config) => {
    setHeadersValues(config);
    return config;
  });

  const id = window.localStorage.getItem(HeaderEnum.USER_ID);

  const errorLog: SystemLogInterface = {
    user: { id: id! },
    timestamp: new Date(),
    level: systemLogLevel,
    source: UserProfileSourceEnum.WEB,
    message: message.substring(0, 511),
    stacktrace: stacktrace || '',
  };

  await errorAxiosInstance({
    method: 'POST',
    url: CoreAPI.REACT_APP_API_SYSTEM_LOG_URL,
    data: JSON.stringify(errorLog),
  });
};

export const getHttpErrorResponse = (error: any) => {
  const httpCoreError = error as HTTPCoreErrorResponse;
  const axiosHttpError = error as AxiosError;

  let message = '';
  if (httpCoreError && httpCoreError.detail) {
    if (Array.isArray(httpCoreError.detail.message)) message = httpCoreError.detail.message.join(',');
    else message = httpCoreError.detail.message ?? 'Unknown error.';
  } else if (axiosHttpError && axiosHttpError.isAxiosError) {
    message = axiosHttpError.message;
  }
  return message;
};

axiosInstance.interceptors.request.use((config) => {
  setHeadersValues(config);
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorObj = (error.response && error.response.data) || error;
    return Promise.reject(errorObj);
  }
);

export default axiosInstance;
